@import url(https://fonts.googleapis.com/css?family=Julius+Sans+One|Jura|Nixie+One|Syncopate|Unica+One|Vollkorn);
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.border {
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
}

.round {
  border-radius: 0.28571429rem;
}

.w-5 {
  width: 5% !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-60 {
  width: 60% !important
}

.w-75 {
  width: 75% !important;
}

.w-90 {
  width: 90% !important;
}

.w-95 {
  width: 95% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-5 {
  height: 5rem !important;
}

.h-4 {
  height: 4rem !important;
}

.h-3 {
  height: 3rem !important;
}

.h-2 {
  height: 2rem !important;
}

.h-1 {
  height: 1rem !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

.flex {
  display: -webkit-flex !important;
  display: flex !important;
}

.f-wrap {
  -webkit-flex-wrap: wrap !important;
          flex-wrap: wrap !important;
}

.column-gap {
  grid-column-gap: 1rem;
  -webkit-column-gap: 1rem;
          column-gap: 1rem;
}

.ai-center {
  -webkit-align-items: center !important;
          align-items: center !important;
}

.jc-center {
  -webkit-justify-content: center !important;
          justify-content: center !important;
}

.jc-between {
  -webkit-justify-content: space-between !important;
          justify-content: space-between !important;
}

.jc-around {
  -webkit-justify-content: space-around !important;
          justify-content: space-around !important;
}

.jc-even {
  -webkit-justify-content: space-evenly !important;
          justify-content: space-evenly !important;
}

.jc-end {
  -webkit-justify-content: flex-end !important;
          justify-content: flex-end !important;
}

.jc-start {
  -webkit-justify-content: flex-start !important;
          justify-content: flex-start !important;
}

.fd-row {
  -webkit-flex-direction: row !important;
          flex-direction: row !important;
}

.fd-column {
  -webkit-flex-direction: column !important;
          flex-direction: column !important;
}

.t-center {
  text-align: center !important;
}

.t-left {
  text-align: left !important;
}

.t-right {
  text-align: right !important;
}

.fw-100 {
  font-weight: 100 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fs-1 {
  font-size: 1rem !important;
}

.fs-x-small {
  font-size: x-small !important;
}

.fs-2 {
  font-size: 2rem !important;
}

.ff-jura {
  font-family: 'Jura', sans-serif !important;
}

.float-right {
  float: right !important;
}

.float-left {
  float: left !important;
}

.absolute {
  position: absolute !important;
}

.relative {
  position: relative !important;
}

.d-block {
  display: block !important;
}

.d-none {
  display: none !important;
}

.bg-clear {
  background-color: #fff !important;
}

.bg-black {
  background-color: #000 !important;
}

.c-black {
  color: #313131 !important;
}

.c-red {
  color: red;
}

.c-white {
  color: #fff !important;
}

.t-20 {
  top: 20% !important;
}

.t-2r {
  top: 2rem !important;
}

.t-4r {
  top: 4rem !important;
}

.l-0 {
  left: 0 !important;
}

.l-1r {
  left: 1rem !important;
}

.r-0 {
  right: 0 !important;
}

.r-1r {
  right: 1rem !important;
}

.b-0 {
  bottom: 0 !important;
}

.b-2r {
  bottom: 2rem !important;
}

.primary {
  background-color: #bdbdbd !important;
}

.primary-d {
  background-color: #8d8d8d !important;
}

.primary-l {
  background-color: #efefef !important;
}

.fixed {
  position: fixed !important;
}

.ofy-auto {
  overflow-y: auto !important;
}

.tr-hover tr:hover {
  background-color: yellow !important;
}

.pointer {
  cursor: pointer;
}

.mb-hover:hover {
  margin-top: -1rem !important;
}

.well {
  box-shadow: inset 2px 2px 1px rgba(0,0,0,.05);
}

.yellowgreen {
  color: darkgreen !important;
}

.game-time {
  background-color: darkslategray !important;
  color: white !important;
}

.slate-gray {
  color: darkslategray !important;
}

.away-line {
  /*background-color: #1b71b1 !important;*/
  background-color: #00b200 !important;
  color: white !important;
}

.home-line {
  /*background-color: #07016b !important;*/
  background-color: #deea16 !important;
  color: darkslategrey !important;
}

.Menu-Button {
  float: left;
  display: inline-block;
  margin: 8px !important;
  color: white !important
}

.inline {
  display: inline-block !important;
}

.va-center {
  vertical-align: center !important;
}

.Calendar-Input-Wrapper {
  width: 94%;
  text-align: center;
  margin: 15px auto auto;
}

.Calendar-Date-Input {
  margin-top: 15px;
  margin-left: auto;
  display: inline-block;
  text-align: center !important;
}

.Games-Container {
  width: 50% !important;
}

@media only screen and (max-width: 600px) {
  .Games-Container {
    width: 96% !important;
  }

  .Menu-Button {
    margin: 8px !important;
    color: white !important;
  }

  .Calendar-Input-Wrapper {
    color: darkslategrey !important;
    width: 100% !important;
    text-align: center;
    margin: 15px auto auto;
  }
}

.BaseballSim-Header {
  font-family: "Fira Code Medium", serif !important;
  color: darkslategrey !important;
  margin: 1rem auto 1rem auto !important;
}


.App {
  text-align: left;
}

.App-header {
  background-color: #00b200;
  height: 100px;
  padding: 35px;
}

.App-title {
  font-size: 1.5em;
  color: #ffffff !important;
  margin: auto !important;
  /*float: right;
  margin-right: 50% !important;*/
  width: 9.4em;
}

.App-SearchBox {
  padding: 0 !important;
  margin: 0 10px 0 0;
}

#App-NavBar {
  border-radius: 0px !important;
  margin: 0;
  background-color: #4c4747 !important;
}

.NavBar {
  margin-left: 20% !important;
}

.a-left {
  text-align: left !important;
}

.mr-1 {
  margin-right: 1em !important;
}

@media only screen and (max-width: 600px) {
  .App-SearchBox {
    display: None;
  }
}

#root {
  height: 100% !important;
}
.Games-Table {
    margin: 25px auto !important;
    width: 60% !important;
}

.Games-Menu-Header {
    text-align: center !important;
    font-size: 1.25em;
}

.Games-Menu {
    position: relative;
    left: 25px;
}

@media only screen and (max-width: 600px) {
    .Games-Table {
        margin: 25px auto !important;
        width: 94% !important;
    }
}
#Home-ReleaseNotes {
    width: 25%;
    margin: 25px auto;
    text-align: center;
}

#Home-ReleaseNotes li{
    text-align: left;
    margin-left: 25px;
}

@media only screen and (max-width: 600px) {
    #Home-ReleaseNotes {
        width: 90%;
        margin: 15px auto;
        text-align: center;
    }

    #Home-ReleaseNotes li{
        text-align: left;
        margin-left: 5px;
    }
}

.Metrics-AccountsTable {
    table-layout: fixed;
    margin: 25px auto !important;
    width: 60% !important;
}

.green {
    color: green !important;
    font-weight: bold !important;
}

.red {
    color: red !important;
    font-weight: bold !important;
}

@media only screen and (max-width: 600px) {
    .Metrics-AccountsTable {
        width: 94% !important;
    }
}
.Player-Table {
    margin: 25px auto !important;
    width: 70% !important;
}

.Player-Segment {
    border: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    padding: 0 !important;
}


@media only screen and (max-width: 600px) {
    .Player-Table {
        width: 98% !important;
    }
}
.Lineups-Table {
     margin: 25px auto !important;
 }

.Odds-Table {
    margin: 25px auto !important;
}

.Teams-Table {
    margin: 25px auto !important;
}

.Boxscore {
    width: 70% !important;
    margin: auto !important;
}

.SimulationResults-Header {
    margin-top: 25px !important;
}

.sportsbook-name {
    margin-left: .5em !important;
}

.red {
    color: red !important;
}

@media only screen and (max-width: 600px) {
    .Boxscore {
        width: 95% !important;
    }
}

.RegResults-AccountsTable {
    table-layout: fixed;
    margin: 25px auto !important;
    width: 80% !important;
}

.RegressionResults-Header {
    margin-top: 25px !important;
    margin-left: 10% !important;
}

.green {
    color: green !important;
    font-weight: bold !important;
}

.red {
    color: red !important;
    font-weight: bold !important;
}

@media only screen and (max-width: 600px) {
    .RegResults-AccountsTable {
        width: 94% !important;
    }

    .RegressionResults-Header {
        margin-top: 20px !important;
        margin-left: 3% !important;
    }
}
body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}

.Team-Table {
    margin: 25px auto !important;
    width: 60% !important;
}

.Team-Header {
    font-size: 20px;
    text-align: left !important;
    padding-left: 35px !important;
}

.Results-Header {
    margin: 25px auto !important;
    width: 60% !important;
}

@media only screen and (max-width: 600px) {
    .Team-Table {
        width: 90% !important;
    }

    .Results-Header {
        margin: 25px auto !important;
        width: 90% !important;
    }
}

.LiveScore {
    margin-left: 20%;
    text-align: left;
}

.Inning-Status {
    color: red !important;
    margin-bottom: 5px;
    text-align: center !important;
}

.Wager-Table {
    margin: 25px auto !important;
    width: 60% !important;
}

.Wager-Date-Input-Wrapper {
    width: 94%;
    text-align: center;
    margin: 15px auto auto;
}

.Wager-Date-Input {
    margin-top: 15px;
    margin-left: auto;
    display: inline-block;
    text-align: center !important;
}

@media only screen and (max-width: 600px) {
    .Wager-Table {
        width: 94% !important;
    }

    .LiveScore {
        margin-left: 5%;
        text-align: left;
    }
}
.Login-Wrapper {
    margin: 30px auto auto;
    width: 30%;
    text-align: center;
}

.Login-Input {
    width: 100%;
    padding: 8px 20px;
    margin: 4px 0;
    display: inline-block;
    border: 1px solid #D3D3D3;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: #F5F5F5;
}

.Login-Button {
    float: right;
    border: 1px solid #D3D3D3;
    border-radius: 4px;
    box-sizing: border-box;
    line-height: 30px;
    width: 100%;
    background-color: darkslategray;
    color: white;
}

@media only screen and (max-width: 600px) {

    .Login-Wrapper {
        margin: 25px auto auto;
        width: 98%;
        text-align: center;
    }
}
