#Home-ReleaseNotes {
    width: 25%;
    margin: 25px auto;
    text-align: center;
}

#Home-ReleaseNotes li{
    text-align: left;
    margin-left: 25px;
}

@media only screen and (max-width: 600px) {
    #Home-ReleaseNotes {
        width: 90%;
        margin: 15px auto;
        text-align: center;
    }

    #Home-ReleaseNotes li{
        text-align: left;
        margin-left: 5px;
    }
}