.Games-Table {
    margin: 25px auto !important;
    width: 60% !important;
}

.Games-Menu-Header {
    text-align: center !important;
    font-size: 1.25em;
}

.Games-Menu {
    position: relative;
    left: 25px;
}

@media only screen and (max-width: 600px) {
    .Games-Table {
        margin: 25px auto !important;
        width: 94% !important;
    }
}