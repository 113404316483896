body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}

.Team-Table {
    margin: 25px auto !important;
    width: 60% !important;
}

.Team-Header {
    font-size: 20px;
    text-align: left !important;
    padding-left: 35px !important;
}

.Results-Header {
    margin: 25px auto !important;
    width: 60% !important;
}

@media only screen and (max-width: 600px) {
    .Team-Table {
        width: 90% !important;
    }

    .Results-Header {
        margin: 25px auto !important;
        width: 90% !important;
    }
}