
.LiveScore {
    margin-left: 20%;
    text-align: left;
}

.Inning-Status {
    color: red !important;
    margin-bottom: 5px;
    text-align: center !important;
}

.Wager-Table {
    margin: 25px auto !important;
    width: 60% !important;
}

.Wager-Date-Input-Wrapper {
    width: 94%;
    text-align: center;
    margin: 15px auto auto;
}

.Wager-Date-Input {
    margin-top: 15px;
    margin-left: auto;
    display: inline-block;
    text-align: center !important;
}

@media only screen and (max-width: 600px) {
    .Wager-Table {
        width: 94% !important;
    }

    .LiveScore {
        margin-left: 5%;
        text-align: left;
    }
}