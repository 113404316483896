.Player-Table {
    margin: 25px auto !important;
    width: 70% !important;
}

.Player-Segment {
    border: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    padding: 0 !important;
}


@media only screen and (max-width: 600px) {
    .Player-Table {
        width: 98% !important;
    }
}