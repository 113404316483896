.Lineups-Table {
     margin: 25px auto !important;
 }

.Odds-Table {
    margin: 25px auto !important;
}

.Teams-Table {
    margin: 25px auto !important;
}

.Boxscore {
    width: 70% !important;
    margin: auto !important;
}

.SimulationResults-Header {
    margin-top: 25px !important;
}

.sportsbook-name {
    margin-left: .5em !important;
}

.red {
    color: red !important;
}

@media only screen and (max-width: 600px) {
    .Boxscore {
        width: 95% !important;
    }
}