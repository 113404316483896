.App {
  text-align: left;
}

.App-header {
  background-color: #00b200;
  height: 100px;
  padding: 35px;
}

.App-title {
  font-size: 1.5em;
  color: #ffffff !important;
  margin: auto !important;
  /*float: right;
  margin-right: 50% !important;*/
  width: 9.4em;
}

.App-SearchBox {
  padding: 0 !important;
  margin: 0 10px 0 0;
}

#App-NavBar {
  border-radius: 0px !important;
  margin: 0;
  background-color: #4c4747 !important;
}

.NavBar {
  margin-left: 20% !important;
}

.a-left {
  text-align: left !important;
}

.mr-1 {
  margin-right: 1em !important;
}

@media only screen and (max-width: 600px) {
  .App-SearchBox {
    display: None;
  }
}

#root {
  height: 100% !important;
}