
.RegResults-AccountsTable {
    table-layout: fixed;
    margin: 25px auto !important;
    width: 80% !important;
}

.RegressionResults-Header {
    margin-top: 25px !important;
    margin-left: 10% !important;
}

.green {
    color: green !important;
    font-weight: bold !important;
}

.red {
    color: red !important;
    font-weight: bold !important;
}

@media only screen and (max-width: 600px) {
    .RegResults-AccountsTable {
        width: 94% !important;
    }

    .RegressionResults-Header {
        margin-top: 20px !important;
        margin-left: 3% !important;
    }
}