.Login-Wrapper {
    margin: 30px auto auto;
    width: 30%;
    text-align: center;
}

.Login-Input {
    width: 100%;
    padding: 8px 20px;
    margin: 4px 0;
    display: inline-block;
    border: 1px solid #D3D3D3;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: #F5F5F5;
}

.Login-Button {
    float: right;
    border: 1px solid #D3D3D3;
    border-radius: 4px;
    box-sizing: border-box;
    line-height: 30px;
    width: 100%;
    background-color: darkslategray;
    color: white;
}

@media only screen and (max-width: 600px) {

    .Login-Wrapper {
        margin: 25px auto auto;
        width: 98%;
        text-align: center;
    }
}