
.Metrics-AccountsTable {
    table-layout: fixed;
    margin: 25px auto !important;
    width: 60% !important;
}

.green {
    color: green !important;
    font-weight: bold !important;
}

.red {
    color: red !important;
    font-weight: bold !important;
}

@media only screen and (max-width: 600px) {
    .Metrics-AccountsTable {
        width: 94% !important;
    }
}